import React, { useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Switch from "../components/switch";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import "../styles/page2.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Page2 = ({
  invCode,
  amount,
  setAmount,
  discounts,
  setDiscounts,
  isFree,
  alertedFields,
}) => {
  let filled = useSelector((state) => state.filled);
  const [hasInvite, setHasInvite] = useState(false);
  const [validationError, setValidationError] = useState(["", "", "", ""]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConirm] = useState(["", ""]);
  const [visibles, setVisibles] = useState([false, false]);
  const [inviteData, setInviteData] = useState();
  const [localFilled, setLocalFilled] = useState([
    filled[0],
    filled[1],
    filled[2],
    filled[3],
    filled[4],
    filled[5],
    filled[6],
  ]);
  const dispatch = useDispatch();
  let data = useSelector((state) => state.data);
  const INVCODE = 99;

  useEffect(() => {
    let tmp = [...validationError];
    if (confirm[0] !== email) {
      tmp[2] = "The email is not matching!";
      dispatch({ type: "FORM", payload: 4, value: false });
    } else {
      tmp[2] = "";
      dispatch({ type: "FORM", payload: 4, value: true });
    }
    if (confirm[1] !== password) {
      tmp[3] = "The Password is not matching!";
      dispatch({ type: "FORM", payload: 6, value: false });
    } else {
      tmp[3] = "";
      dispatch({ type: "FORM", payload: 6, value: true });
    }

    setValidationError(tmp);
  }, [email, password]);

  const visibleHandler = (idx) => {
    let tmp = [...visibles];
    tmp[idx] = !visibles[idx];
    setVisibles(tmp);
  };

  const valueHandler = (val, idx, key) => {
    switch (key) {
      case "PHONE": {
        let tmp = [...validationError];
        if (val.match(/^[0-9]{8,}$/g)) {
          tmp[0] = "";
          dispatch({ type: "FORM", payload: idx, value: true });
        } else {
          tmp[0] = "Please fill phone number correctly!";
          dispatch({ type: "FORM", payload: idx, value: false });
        }
        setValidationError(tmp);
        dispatch({ type: "DATA", data_type: key, data: val });
        break;
      }
      case "EMAIL": {
        let tmp = [...validationError];
        if (val.match(/^[\w.]{2,}@[\w]{2,}\.[\w]{2,}/g)) {
          tmp[1] = "";
          dispatch({ type: "FORM", payload: idx, value: true });
        } else {
          tmp[1] = "Please fill your email correctly!";
          dispatch({ type: "FORM", payload: idx, value: false });
        }
        setValidationError(tmp);
        setEmail(val);
        dispatch({ type: "DATA", data_type: key, data: val });
        break;
      }
      case "CONFIRM": {
        let tmp = [...validationError];
        let localConfirm = [...confirm];
        if (val === email) {
          tmp[2] = "";
          dispatch({ type: "FORM", payload: idx, value: true });
        } else {
          tmp[2] = "The email is not matching!";
          dispatch({ type: "FORM", payload: idx, value: false });
        }
        localConfirm[0] = val;
        setConirm(localConfirm);
        setValidationError(tmp);
        break;
      }
      case "PASS_CONFIRM": {
        let tmp = [...validationError];
        let localConfirm = [...confirm];
        if (val === password) {
          tmp[3] = "";
          dispatch({ type: "FORM", payload: idx, value: true });
        } else {
          tmp[3] = "The Password is not matching!";
          dispatch({ type: "FORM", payload: idx, value: false });
        }
        localConfirm[1] = val;
        setConirm(localConfirm);
        setValidationError(tmp);
        break;
      }
      default: {
        if (val.length > 0)
          dispatch({ type: "FORM", payload: idx, value: true });
        else dispatch({ type: "FORM", payload: idx, value: false });
        dispatch({ type: "DATA", data_type: key, data: val });
        break;
      }
    }
    let tmpFilled = [...localFilled];
    if (val.length === 0) {
      tmpFilled[idx] = false;
    } else {
      tmpFilled[idx] = true;
    }
    setLocalFilled(tmpFilled);
  };

  const checkInvite = (code) => {
    let myForm = new FormData();
    myForm.append("invCode", code);

    axios
      .post("https://testapp.elify.com/validate/referral-code", myForm)
      .then((res) => {
        setInviteData(res.data);
        changeDiscounts(res.data);
      })
      .catch((err) => console.log(err));
  };

  const changeDiscounts = (data) => {
    if (!isFree) {
      let tmp = [...discounts];
      if (data?.result === 1) {
        if (!tmp.some((el) => el.id === INVCODE)) {
          let newDiscount = {
            name: "Invite Code Discount",
            value: parseFloat(data?.bonus_amount),
            id: INVCODE,
          };

          tmp.push(newDiscount);
          setDiscounts(tmp);
        }
      } else {
        removeDiscount(INVCODE);
      }
    }
  };

  const removeDiscount = (discount_id) => {
    let tmp = [...discounts];
    tmp = tmp.filter((el) => el.id !== discount_id);
    setDiscounts(tmp);
  };

  const switchHandler = (val) => {
    if (!val) {
      removeDiscount(INVCODE);
    } else {
      checkInvite(invCode);
    }
    setHasInvite(val);
  };

  // const alertEmpties = (idx) => {
  //   let tmp = [...alertEmpties];
  //   tmp[idx].focused = true;
  // };

  return (
    <div className="page2">
      <div className="title">Personal Info</div>
      <div className="wrapper">
        <TextField
          required
          label="First Name"
          fullWidth
          onChange={(e) => valueHandler(e.target.value, 0, "FIRST_NAME")}
          defaultValue={data.first_name}
          error={alertedFields && !localFilled[0]}
        />
        <TextField
          required
          label="Last Name"
          fullWidth
          onChange={(e) => valueHandler(e.target.value, 1, "LAST_NAME")}
          defaultValue={data.last_name}
          error={alertedFields && !localFilled[1]}
        />
      </div>
      <TextField
        required
        label="Phone Number"
        fullWidth
        onChange={(e) => valueHandler(e.target.value, 2, "PHONE")}
        error={validationError[0] !== "" || (alertedFields && !localFilled[2])}
        helperText={validationError[0]}
        defaultValue={data.phone}
      />

      <div className="wrapper">
        <TextField
          required
          label="Email"
          onChange={(e) => valueHandler(e.target.value, 3, "EMAIL")}
          error={
            validationError[1] !== "" || (alertedFields && !localFilled[3])
          }
          helperText={validationError[1]}
          defaultValue={data.email}
        />
        <TextField
          required
          label="Confirm Email"
          onChange={(e) => valueHandler(e.target.value, 4, "CONFIRM")}
          error={
            validationError[2] !== "" || (alertedFields && !localFilled[4])
          }
          helperText={validationError[2]}
          defaultValue={data.email}
        />
      </div>
      <div className="wrapper">
        <TextField
          type={visibles[0] ? "text" : "password"}
          label="Password"
          required
          fullWidth
          error={alertedFields && !localFilled[5]}
          input
          onBlur={() => setVisibles([false, false])}
          onChange={(e) => {
            valueHandler(e.target.value, 5, "PASSWORD");
            setPassword(e.target.value);
          }}
          defaultValue={data.password}
          InputProps={{
            endAdornment: visibles[0] ? (
              <Visibility
                style={{ cursor: "pointer" }}
                onClick={() => visibleHandler(0)}
              />
            ) : (
              <VisibilityOff
                style={{ cursor: "pointer" }}
                onClick={() => visibleHandler(0)}
              />
            ),
          }}
        />
        <TextField
          type={visibles[1] ? "text" : "password"}
          label="Confirm Password"
          required
          fullWidth
          input
          onBlur={() => setVisibles([false, false])}
          onChange={(e) => {
            valueHandler(e.target.value, 6, "PASS_CONFIRM");
          }}
          defaultValue={data.password}
          error={
            validationError[3] !== "" || (alertedFields && !localFilled[4])
          }
          helperText={validationError[3]}
          InputProps={{
            endAdornment: visibles[1] ? (
              <Visibility
                style={{ cursor: "pointer" }}
                onClick={() => {
                  visibleHandler(1);
                }}
              />
            ) : (
              <VisibilityOff
                style={{ cursor: "pointer" }}
                onClick={() => visibleHandler(1)}
              />
            ),
          }}
        />
      </div>

      <div className="invite">
        <span>Did someone invite you?</span>
        <Switch
          getValue={switchHandler}
          defaultValue={invCode ? true : false}
        />
      </div>
      {hasInvite && (
        <div>
          <TextField
            onChange={(e) => checkInvite(e.target.value)}
            label="Invite Code"
            required
            fullWidth
            defaultValue={invCode || ""}
            error={inviteData?.result === 0}
            helperText={
              inviteData?.result === 1
                ? `Invited by ${inviteData.user?.display_name}`
                : inviteData?.message
            }
          />
        </div>
      )}
    </div>
  );
};

export default Page2;
