import "./App.css";
import { useEffect, useState } from "react";
import Axios from "axios";
import Header from "./components/header";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Page1 from "./pages/page1";
import Page2 from "./pages/page2";
import Page3 from "./pages/page3";
import Page4 from "./pages/page4";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Progress from "@material-ui/core/CircularProgress";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Confetti from "react-dom-confetti";
import Congrats from "./pages/congrats";

function App() {
  const [stage, setStage] = useState(1);
  const [confetti, setConfetti] = useState(false);
  const [errors, setErrors] = useState([]);
  const [test, setTest] = useState(false);
  const [instance, setInstance] = useState("");
  const [loading, setLoading] = useState(
    !useSelector((state) => state.loading)
  );
  const [alertedFields, setAlertedFields] = useState(false);
  const [amount, setAmount] = useState({
    total: 0,
    total_discount: 0,
    monthly: 0,
  });
  const [discounts, setDiscounts] = useState([]);
  let urlLocation = window.location;
  const dispatch = useDispatch();
  let email = useSelector((state) => state.data.email);
  let filled = useSelector((state) => state.filled);
  let isLoading = useSelector((state) => state.loading);
  let data = useSelector((state) => state.data);
  let pack = useSelector((state) => state.pack);

  const rsaidCodes = [
    {
      rsaid: "teamelite",
      invCode: "1821921933",
    },
    {
      rsaid: "branduavacen",
      invCode: "8655191700",
    },
    {
      rsaid: "brandugopro",
      invCode: "3418518322",
    },
    {
      rsaid: "nirvanagold",
      invCode: "5325681266",
    },
    {
      rsaid: "teamunited",
      invCode: "4457525477",
    },
    {
      rsaid: "colorstreet",
      invCode: "4457525477",
    },
    {
      rsaid: "savvi",
      invCode: "2502833911",
    },
    {
      rsaid: "onpassive",
      invCode: "4730401100",
    },
  ];

  useEffect(() => setLoading(!loading), [isLoading]);

  useEffect(() => {
    const tmpAmount = {
      total: 0,
      monthly: 0,
      total_discount: 0,
    };

    pack.forEach((el, idx) => {
      tmpAmount.total += el.value || 0;
      tmpAmount.monthly += el.is_monthly ? el.value : 0;
      tmpAmount.total_discount += el.discount_value || 0;
    });

    setAmount(tmpAmount);
  }, [pack]);

  const calculateDiscounts = () => {
    let tmp = 0;
    discounts.forEach((el) => (tmp += el.value));
    return tmp;
  };

  const config = {
    angle: 180,
    spread: 360,
    startVelocity: 40,
    elementCount: "500",
    dragFriction: 0.12,
    duration: "10000",
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  let params = new URLSearchParams(urlLocation.search);
  let urlKey = Array.from(params.keys()).reduce(
    (acc, key) => ({ ...acc, [key]: params.get(key) }),
    {}
  );

  const defineInviteCode = () => {
    if (rsaidCodes.some((el) => el.rsaid === urlKey.rsaid)) {
      let tmpCode = rsaidCodes.filter((el) => el.rsaid === urlKey.rsaid);
      tmpCode = tmpCode[0];
      return tmpCode.invCode;
    } else {
      if (urlKey.invitecode) {
        return urlKey.invitecode;
      } else return null;
    }
  };

  useEffect(() => defineInviteCode(), []);

  async function purchase() {
    if (instance) {
      setLoading(true);
      const { nonce } = await instance.tokenize();
      dispatch({ type: "DATA", data_type: "NONCE", data: nonce });
      Axios.post("https://testaffiliate.elify.com/api_signup", {
        data: JSON.stringify(data),
        service: "/api_signup",
        timeout: {},
      })
        .then((res) => {
          console.log(res);
          setStage(stage + 1);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const pageRenderer = () => {
    switch (stage) {
      case 1:
        return (
          <Page1
            isAffiliate={urlKey.affiliate === "1"}
            invCode={defineInviteCode()}
            setAmount={setAmount}
            amount={amount}
            setDiscounts={setDiscounts}
            discounts={discounts}
          />
        );
      case 2:
        return (
          <Page2
            invCode={defineInviteCode()}
            amount={amount}
            setAmount={setAmount}
            defaultPrice={amount}
            setDiscounts={setDiscounts}
            discounts={discounts}
            isFree={pack[0].value === 0}
            alertedFields={alertedFields}
          />
        );
      case 3:
        return <Page3 setInstance={setInstance} />;
      case 4: {
        return urlKey.affiliate === "1" ? (
          <Page4 setTask={setStage} />
        ) : (
          <Congrats setAutoConfetti={setConfetti} />
        );
      }
      default:
        return <Congrats setAutoConfetti={setConfetti} />;
    }
  };

  // useEffect(() => {

  // }, [pack]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const today = (m) => {
    let date = new Date();
    let year = date.getFullYear();
    if (m > 11) {
      year = year + 1;
      m = 0;
    } else if (m < 0) {
      year = year - 1;
      m = 11;
    }
    return (
      months[m] +
      " " +
      String(date.getDate()).padStart(2, "0") +
      ", " +
      year +
      " "
    );
  };

  const service = () => {
    switch (stage) {
      case 1: {
        setStage(stage + 1);
        break;
      }
      case 2: {
        let tmp = [...errors];
        if (filled.indexOf(false) === -1) {
          let errorIdx = errors.indexOf("Please fill the required fields.");
          tmp.splice(errorIdx, 1);
          setTest(false);
          dispatch({ type: "LOADER", isLoading: true });
          let body = {
            data: `{email : "${email}"}`,
            service: "/account/check_email",
            timeout: {},
          };
          const url = "https://testaffiliate.elify.com/api_call";

          Axios.post(url, body)
            .then((res) => {
              dispatch({ type: "LOADER", isLoading: false });
              if (!JSON.parse(res.data)?.success) {
                let er = errors.indexOf("Your email is already registered.");
                tmp.splice(er, 1);
                setStage(stage + 1);
                setTest(false);
              } else {
                tmp.push("Your email is already registered.");
                setTest(true);
              }
            })
            .catch((err) => console.log(err));
        } else {
          setAlertedFields(true);
          if (errors.indexOf("Please fill the required fields.") === -1)
            tmp.push("Please fill the required fields.");
          setTest(true);
        }
        setErrors(tmp);
        break;
      }
      case 3: {
        purchase();
        break;
      }
      default:
        console.log("Default");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const backHandler = () => {
    if (stage === 2) {
      setErrors([]);
    }
    stage > 0 && setStage(stage - 1);
  };

  return (
    <div className="App">
      <div className="loader">
        <Backdrop
          open={loading}
          onClick={() => dispatch({ type: "LOADER", isLoading: false })}
        >
          <Progress color="inherit" />
        </Backdrop>
      </div>

      <Header
        stage={stage}
        setStage={setStage}
        isAffiliate={urlKey.affiliate === "1"}
      />
      <div className={stage >= 4 ? "one-wrap" : "body"}>
        {stage >= 4 && (
          <Confetti active={confetti} config={config} className="myConfetti" />
        )}
        <div className="content">{pageRenderer()}</div>
        {stage < 4 && (
          <div className="side">
            <div className="blue order-summary">Order Summary</div>
            <div className="blue info">
              Your credit card will be charged today:{" "}
              {today(new Date().getMonth())}
              for (${(amount?.total - calculateDiscounts()).toFixed(2)}).
            </div>
            <div className="blue info">
              Each month thereafter your credit card will be charged ($
              {(amount?.monthly - calculateDiscounts()).toFixed(2)}
              ).
            </div>
            <div className="blue info">You may cancel at any time.</div>
            <div className="main-subs">
              {pack.map((el, idx) => (
                <div className="blue pack_item" key={idx}>
                  <span>{el.name}</span>
                  <span>
                    {el.value === 0 ? "free" : "$" + el.value.toFixed(2)}
                  </span>
                </div>
              ))}
            </div>
            <div>
              <div className="fee space" style={{ color: "#a5b5c5" }}>
                <span>Subtotal:</span>
                <span>${amount?.total.toFixed(2)}</span>
              </div>
              {discounts.map((el, idx) => (
                <div
                  className="fee space"
                  style={{ color: "#307815" }}
                  key={idx}
                >
                  <span>{el?.name}</span>
                  <span>{`(-$${el?.value.toFixed(2)})`}</span>
                </div>
              ))}

              {discounts.map(
                (el, idx) =>
                  el.discount_name && (
                    <div
                      className="fee space"
                      style={{ color: "#307815" }}
                      key={idx}
                    >
                      <span>{el.discount_name}</span>
                      <span>{`(-$${el.discount_value.toFixed(2)})`}</span>
                    </div>
                  )
              )}
              <div className="fee space" style={{ color: "#939bcc" }}>
                <span>Total Due Today:</span>
                <span style={{ fontSize: "24px" }}>
                  ${(amount.total - calculateDiscounts()).toFixed(2)}
                </span>
              </div>
              <div className="fee space">
                <span style={{ color: "#a5b5c5" }}>
                  Monthly Subscription Fee:
                </span>
                <span style={{ color: "#3b4964" }}>
                  ${(amount?.monthly - calculateDiscounts()).toFixed(2)}
                </span>
              </div>
              <div className="blue scope">
                (starting {today(new Date().getMonth() + 1)})
              </div>
            </div>
            {test &&
              errors.map((el, idx) => (
                <div className="error" key={idx}>
                  <img src="/warning.svg" alt="warning" />
                  {el}
                </div>
              ))}

            <ColorButton variant="contained" color="primary" onClick={service}>
              Continue
            </ColorButton>

            {stage > 1 && (
              <div style={{ marginTop: "20px" }}>
                <Button
                  classes={{ root: { background: "white" } }}
                  variant="contained"
                  style={{ background: "white", fontWeight: "500" }}
                  onClick={backHandler}
                  fullWidth
                >
                  back
                </Button>
              </div>
            )}
            <div className="have-account">
              Already have an account?{" "}
              <Button
                variant="contained"
                style={{
                  background: "#75c1ad",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                Sign in
              </Button>
            </div>
          </div>
        )}
      </div>
      <GoogleReCaptchaProvider reCaptchaKey="6LcQvMUUAAAAANkgjOBC2LR24JrsMLXWKIcbGIP1">
        <GoogleReCaptcha
          onVerify={(token) =>
            dispatch({ type: "DATA", data_type: "CAPTCHA", data: token })
          }
        />
      </GoogleReCaptchaProvider>
    </div>
  );
}

const ColorButton = withStyles((theme) => ({
  root: {
    width: "100%",
    color: theme.palette.getContrastText("#4d7acf"),
    backgroundColor: "#4d7acf",
    "&:hover": {
      backgroundColor: "#345ca7",
    },
  },
}))(Button);
export default App;
