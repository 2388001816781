import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../styles/congrats.scss";
import Arrow from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";

const Congrats = ({ user, setAutoConfetti }) => {
  const [finish, setFinish] = useState(false);
  let userName = useSelector((state) => state.data?.first_name);
  setAutoConfetti(true);
  return (
    <div className="congrats">
      <div
        className="congrats--header"
        style={{ background: "url(/bg.png)", backgroundSize: "cover" }}
      >
        {finish ? (
          <div className="title">Welcome</div>
        ) : (
          <div className="title">Congratulations!</div>
        )}
        <img src="/pattern2.png" alt="pattern" className="pattern two" />
        <img src="/pattern1.png" alt="pattern" className="pattern one" />
      </div>
      {finish ? (
        <div className="note first">
          A one-time 100 coin ($ 1USD) Affiliate enrollment fee has been
          deducted from your coin count.
        </div>
      ) : (
        <div className="user">Welcome {userName}!</div>
      )}
      {finish ? (
        <div className="note second">
          Once you have completed the set up your eWallet account, return to
          Elify to redeem your earnings!
        </div>
      ) : (
        <div className="text">
          We are so excited to welcome you to the Elify Community! You have
          successfully signed up for Shuffle app and have activated your
          Affiliate status!
        </div>
      )}
      {finish ? (
        <div className="note third">
          <div>
            As an Affiliate, anytime you earn 2,500 coinds (worth $25USD) or
            more, you can click "Redeem" to load your earnings into your eWallet
            account. Once your funds are loaded, you'll have a variety of
            options for withdrawing your earnings, inlcuding by check, direct
            deposit, or wire transfer (additional fees may apply).
          </div>
          <div>
            If your Affiliate enrollment fee brought your coin count below 2,500
            coins, you may still redeem your earnings*.
          </div>
          <div>
            *Please note this offer is only available for your first redemption.
            Future redemptions will require a minimum balance of 2,500 coins.
          </div>
        </div>
      ) : (
        <div className="emails">
          <div>Please check your inbox for the following 3 emails:</div>

          <div> 1. An email from Elify confirming your order.</div>
          <div> 2. An email from Elify confirming Affiliate status.</div>
          <div>
            {" "}
            3. An email from eWallet with instructions on completing the setup
            of your eWallet account.
          </div>
        </div>
      )}
      {finish ? (
        <div className="buttons">
          <Button>build your first card</Button>
          <Button>acces affiliate central</Button>
          <Button>GO TO eWALLET</Button>
        </div>
      ) : (
        <div className="arrow-container">
          <Button
            endIcon={<Arrow />}
            style={{ color: "#64a7eb ", fontSize: "14px" }}
            onClick={() => setFinish(!finish)}
          >
            next
          </Button>
        </div>
      )}
    </div>
  );
};

export default Congrats;
