import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { BraintreeHostedFields } from "braintree-web-react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Countries from "../json/counties.json";
import States from "../json/states.json";
import "../styles/page3.scss";
import { useDispatch, useSelector } from "react-redux";

const Page3 = ({ setInstance }) => {
  const [method, setMethod] = useState("");
  const [token, setToken] = useState("");

  const dispatch = useDispatch();
  const url = "https://testaffiliate.elify.com/api_call";
  const tokenObj = {
    data: "{}",
    service: "/billing/get_client_token",
    timeout: {},
  };
  let form = useSelector((state) => state.data);
  useEffect(() => {
    dispatch({ type: "LOADER", isLoading: true });
    Axios.post(url, tokenObj)
      .then((res) => {
        setToken(JSON.parse(res.data)?.token);
        dispatch({ type: "LOADER", isLoading: false });
      })
      .catch((err) => console.log(err));
  }, []);

  const ColorButton = withStyles((theme) => ({
    root: {
      fontSize: "12px",
      fontWeight: "500",
      color: theme.palette.getContrastText("#4d7acf"),
      backgroundColor: "rgb(59,122,213)",
      "&:hover": {
        backgroundColor: "#345ca7",
      },
    },
  }))(Button);

  return (
    <div className="page3">
      <div className="title">Payment Info</div>
      {method === "" && (
        <div>
          <div className="payment-method">
            Please select your payment method
          </div>
          <div className="methods">
            <Button
              size="large"
              variant="contained"
              style={{
                fontSize: "14px",
                background: "#4b9bda",
                color: "white",
                fontStyle: "italic",
                textTransform: "initial",
                fontWeight: "700",
              }}
            >
              PayPal
            </Button>{" "}
            <span className="or">or</span>
            <div
              onClick={() => setMethod("credit_card")}
              className="credit-card"
            >
              Credit card
            </div>
          </div>
        </div>
      )}
      {method === "credit_card" && (
        <div>
          <div className="method-changer" onClick={() => setMethod("")}>
            Change Payment Method
          </div>
          <BraintreeHostedFields
            className="drop-in-container"
            options={{
              authorization: token,
            }}
            onInstance={(instance) => setInstance(instance)}
          >
            <div className="wrapper">
              <TextField
                label="Name on Credit Card"
                required
                onChange={(e) =>
                  dispatch({
                    type: "DATA",
                    data_type: "CARD_HOLDER",
                    data: e.target.value,
                  })
                }
              />
              <div className="form-item">
                <label className="hosted-fields--label">Card Number</label>
                <div id="card-number" className="hosted-field"></div>
              </div>
            </div>
            <div className="wrapper">
              <div className="form-item">
                <label className="hosted-fields--label">Expiration Date</label>
                <div id="expiration-date" className="hosted-field"></div>
              </div>
              <div className="form-item">
                <label className="hosted-fields--label">CVV</label>
                <div id="cvv" className="hosted-field" placeholder=""></div>
              </div>
            </div>
            <div className="wrapper">
              <div className="form-item">
                <label className="hosted-fields--label">Postal Code</label>
                <div id="postal-code" className="hosted-field"></div>
              </div>
              <TextField
                label="Billing Street Address"
                required
                fullWidth
                onChange={(e) =>
                  dispatch({
                    type: "DATA",
                    data_type: "STREET_ADDR",
                    data: e.target.value,
                  })
                }
              />
            </div>
          </BraintreeHostedFields>
          <div className="wrapper-triple">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={Countries[0].Alpha2}
                onChange={(e) =>
                  dispatch({
                    type: "DATA",
                    data_type: "COUNTRY_CODE",
                    data: e.target.value,
                  })
                }
              >
                {Countries.map((el, idx) => (
                  <MenuItem key={idx} value={el.Alpha2}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {form?.countryCodeAlpha2 === "US" && (
              <FormControl>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={States[0]}
                  onChange={(e) =>
                    dispatch({
                      type: "DATA",
                      data_type: "STATE",
                      data: e.target.value,
                    })
                  }
                >
                  {States.map((el, idx) => (
                    <MenuItem key={idx} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              label="City"
              required
              onChange={(e) =>
                dispatch({
                  type: "DATA",
                  data_type: "CITY",
                  data: e.target.value,
                })
              }
              error={form?.city.length < 0}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Page3;
