import React, { useState, useEffect } from "react";
import Select from "../components/selectComp";
import "../styles/page1.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Page1 = ({
  isAffiliate,
  invCode,
  setAmount,
  amount,
  setDiscounts,
  discounts,
}) => {
  const dispatch = useDispatch();

  let pack = useSelector((state) => state.pack);

  const [isValid, setIsValid] = useState(false);
  const [availablePacks, setAvailablePacks] = useState([
    {
      name: "Shuffle Subscription",
      value: 29.99,
      is_monthly: true,
      text:
        'Shuffle: $29.99 per month 10 Business Cards, Landing Pages and "Self" Branding and Marketing materials & Unlimited Contacts.',
      img: <img src="/shuffle-logo.svg" className="shuffle" />,
      id: 0,
    },
    {
      name: "Marketplace Upgrade",
      value: 10.0,
      discount_name: "Marketplace BETA Offer Discount",
      discount_value: 5.0,
      is_monthly: true,
      text:
        " Shuffle Marketplace Upgrade: $10 per month. You may cancel at any time.",
      img: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src="/shuffle-logo.svg" className="shuffle" />
          <div>MARKETPLACE</div>
        </div>
      ),
      id: 1,
    },
    {
      name: "Shuffle Upgrade",
      value: 50.0,
      is_monthly: false,
      text: "Shuffle 100 Cards Upgrade: $50 one-time fee",
      img: (
        <div className="hundred_cards">
          <div className="the-cards">CARDS</div>
          <div className="the-hundred">100</div>
        </div>
      ),
      id: 2,
    },
    {
      name: "Shuffle Subscription",
      value: 0,
      is_monthly: false,
      text: "Try Shuffle For Free! 1 Business Card & 10 Contacts",
      id: 3,
    },
  ]);

  const INVCODE = 99;

  const checkInvite = (code) => {
    if (pack[0].value !== 0) {
      let myForm = new FormData();
      myForm.append("invCode", code);

      axios
        .post("https://testapp.elify.com/validate/referral-code", myForm)
        .then((res) => {
          if (
            res.data?.result === 1 &&
            !discounts.some((el) => el.id === INVCODE)
          ) {
            let tmp = [...discounts];
            let data = res.data;
            let newDiscount = {
              name: "Invite Code Discount",
              value: parseFloat(data?.bonus_amount),
              id: INVCODE,
            };
            tmp.push(newDiscount);
            setDiscounts(tmp);
            setIsValid(true);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => checkInvite(invCode), []);

  const clickHandler = (idx) => {
    let tmp = [...pack];
    switch (idx) {
      case 0:
      case 3:
        tmp = [availablePacks[idx]];
        if (idx === 3) {
          setAmount({ total: 0, monthly: 0, total_discount: 0 });
          setDiscounts([]);
        }
        if (idx === 0) {
          let tmpDiscounts = [...discounts];
          tmpDiscounts = tmpDiscounts.filter((el) => el.id === INVCODE);
          setDiscounts(tmpDiscounts);
          checkInvite(invCode);
        }
        break;
      default:
        if (tmp.some((el) => el.id === 3)) {
          tmp = [availablePacks[0]];
        }
        if (tmp.some((el) => el.id === idx)) {
          tmp = tmp.filter((el) => el.id !== idx);
        } else {
          tmp.push(availablePacks[idx]);
        }
        break;
    }
    dispatch({ type: "PACK", pack: tmp });

    if (availablePacks[idx].discount_name) {
      let tmpDiscounts = [...discounts];
      let tmpAmount = amount;
      let disc = availablePacks[idx].discount_value;
      if (!tmpDiscounts.some((elem) => elem.id === idx)) {
        tmpDiscounts.push({
          name: availablePacks[idx].discount_name,
          value: disc,
          id: idx,
        });
      } else {
        tmpDiscounts = tmpDiscounts.filter((el) => el.id !== idx);
      }
      setDiscounts(tmpDiscounts);
    }
  };

  return (
    <div>
      <div className="page1">
        <div className="container">
          <div className="welcome">Welcome!</div>
          <div className="text">
            You are just a few short steps away from activating your Elify
            Affiliate status! You must have Shuffle account in order to promote
            the Shuffle app.
          </div>
          <div className="text">Let's get started!</div>
        </div>
        {!isAffiliate
          ? availablePacks
              .slice(0, availablePacks.length - 1)
              .map((el, idx) => (
                <Select
                  onClick={() => clickHandler(idx)}
                  isClicked={pack.map((el) => el.id).includes(idx)}
                  type="FREE"
                  img={el.img}
                  isHidden={!el.img}
                  key={idx}
                >
                  <div>
                    {isValid && idx === 0 ? (
                      <div>
                        Shuffle:{" "}
                        <span style={{ textDecoration: "line-through" }}>
                          $29.99{" "}
                        </span>{" "}
                        <span style={{ color: "green" }}>$20.00</span> per month
                        10 Business Cards, Landing Pages and "Self" Branding and
                        Marketing materials & Unlimited Contacts.
                      </div>
                    ) : (
                      el.text
                    )}
                  </div>
                </Select>
              ))
          : availablePacks
              .slice(0, availablePacks.length - 1)
              .map((el, idx) => (
                <Select
                  onClick={() => clickHandler(idx)}
                  isClicked={pack.map((el) => el.id).includes(idx)}
                  type="FREE"
                  img={el.img}
                  isHidden={!el.img}
                  key={idx}
                >
                  <div className={!el.img ? "try-free" : ""}>{el.text}</div>
                </Select>
              ))}
        {!isAffiliate && (
          <div className="free-container try-free">
            <span onClick={() => clickHandler(availablePacks.length - 1)}>
              Try Shuffle For Free! 1 Business Card & 10 Contacts
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Page1;
