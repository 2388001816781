import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import "../styles/page4.scss";

const Page4 = ({ setTask }) => {
  const [checked, setChecked] = useState(false);
  const url = "https://testaffiliate.elify.com/api_call";
  const dispatch = useDispatch();

  let data = useSelector((state) => state?.data);
  dispatch({ type: "LOADER", isLoading: true });

  let reqBody = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    birthdate: new Date(1996, 5, 19),
    business_name: "",
    street_1: "",
    street_2: "",
    zip: "",
    tax: "",
    ssn: "",
    phone: data?.phone,
    city: data?.city,
    state: data?.state,
  };
  const service = () => {
    if (
      data.first_name?.length > 0 &&
      data.last_name?.length > 0 &&
      data.email?.match(/^[\w .]{3,}@[\w]{2,}.[\w]{2,}$/g) &&
      checked
    ) {
      axios
        .post(url, {
          data: JSON.stringify(reqBody),
          service: "/affiliate/create/customer",
          timeout: {},
        })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      setTask && setTask(5);
    }
  };

  return (
    <div className="page4">
      <div className="title">Affiliate Activation </div>

      <div className="italic">
        <i>
          Please make sure the information you provide below the Name and
          Birthdate as stated on a government-issued ID as the information can
          not be updated and will be use to verify your identity for fraud
          prevention to ensure that you can cash out your affiliate commission.
        </i>
      </div>
      <div className="grid-two">
        <TextField
          label="First Name"
          required
          fullWidth
          defaultValue={data.first_name}
          onChange={(e) =>
            dispatch({
              type: "DATA",
              data_type: "FIRST_NAME",
              data: e.target.value,
            })
          }
          error={data.first_name.length === 0}
          // value={data.first_name}
        />
        <TextField
          label="Last Name"
          required
          fullWidth
          defaultValue={data.last_name}
          onChange={(e) =>
            dispatch({
              type: "DATA",
              data_type: "LAST_NAME",
              data: e.target.value,
            })
          }
        />
      </div>
      <div className="grid-one">
        <TextField
          label="Email"
          required
          fullWidth
          defaultValue={data.email}
          onChange={(e) =>
            dispatch({
              type: "DATA",
              data_type: "EMAIL",
              data: e.target.value,
            })
          }
        />
      </div>
      <div className="line-one">
        <Checkbox
          color="primary"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <span>
          I agree to the
          <Modal closeAfterTransition BackdropComponent={<div>ad</div>}>
            <Fade>
              <div>Affiliate Agreement</div>
            </Fade>
          </Modal>{" "}
          and the <a href="#aa">Income Disclosure Statement</a>.
        </span>
      </div>
      <div className="button-container">
        <ColorButton
          onClick={() => {
            service();
          }}
        >
          Activate Your Affiliate Status
        </ColorButton>
      </div>
    </div>
  );
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#4d7acf"),
    backgroundColor: "#4d7acf",
    fontSize: "12px",
    textTransform: "capitalize",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#456ebb",
    },
  },
}))(Button);

export default Page4;
