import React from "react";

const Header = ({ stage, setStage, isAffiliate }) => {
  const tasks = isAffiliate
    ? [
        "Sign up for shuffle",
        "Enter your details",
        "Payment",
        "Affiliate activation",
      ]
    : ["Sign up for shuffle", "Enter your details", "Payment"];
  let idx = stage - 1;
  return (
    <div className="header">
      <img src="/elify.svg" alt="elify" className="elify-logo" />
      <div className="tasks">
        <div className={isAffiliate ? "checks" : "checks-three"}>
          {tasks.map(
            (el, idx) =>
              idx + 1 < stage && (
                <img
                  src="/check.png"
                  key={idx}
                  alt={el}
                  style={{ cursor: "pointer" }}
                  onClick={() => setStage && setStage(idx + 1)}
                />
              )
          )}
        </div>
        {!isAffiliate ? (
          <svg
            id="svg3994"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-138 379.1 334.7 34.9"
            fill="#E5E5E5"
          >
            <linearGradient
              id="my-cool-gradient1"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop
                offset={`${(stage + idx) * 20}%`}
                stopColor="#64a7ed"
                stopOpacity="1"
              />
              <stop
                offset={`${stage * 30 + idx * 30}%`}
                stopColor="#e6e6e6"
                stopOpacity="1"
              />
            </linearGradient>
            <g id="g4088" transform="translate(14.818333,3.1961115)">
              <path
                fill="url(#my-cool-gradient1)"
                class="st0"
                d="M132,391.2c0-0.1,0-0.1,0-0.2l-0.1-0.1c-0.1-0.3-0.3-1.1-0.4-1.7c-0.5-2.6-2.3-5.8-4.7-8.1
           c-3.7-3.7-7.1-5.1-12.2-5.1c-3.8,0-6.4,0.7-9.3,2.7c-3.4,2.2-5.3,4.7-7.6,10c-1.4,3.3,1.7,3-32.4,3c-29.7,0-29.8,0-30.7-0.7
           s-1.2-1.2-3.1-5.4c-1.4-3.2-4.5-6.3-7.6-7.8c-9-4.4-19.7-0.9-23.8,7.8c-2.1,4.4-2.2,4.7-3.2,5.4c-0.9,0.7-1,0.7-28.6,0.7
           c-15.3,0-29.3-0.1-31.2-0.3c-3.1-0.2-3.6-0.4-4.4-1.1c-0.5-0.5-1.3-1.8-1.7-3c-1.5-4-4.6-7.7-8.2-9.6c-3-1.6-8.2-2.2-12.8-1.4
           c-3.2,0.6-5.5,1.8-8,4.3c-3.5,3.4-5.1,7.3-5.1,12.7c0,5.5,1.4,9.2,4.8,12.6c2.3,2.3,4.7,3.7,7.3,4.3c4.5,1,10.4,0.5,13.6-1.2
           c3.5-1.8,7.2-6.1,8.3-9.7c0.3-0.9,0.9-2.2,1.4-2.8c0.8-1,1-1,4-1.2c1.7-0.1,16-0.2,31.6-0.2h28.4l1.1,1.1c0.6,0.6,1.2,1.7,1.4,2.3
           c2.9,8.8,11.9,13.9,21.1,11.8c5.6-1.3,10.5-5.8,12.4-11.3c0.3-1,1-2.2,1.6-2.8l1-1.1h60.5l1,1c0.5,0.5,1.3,1.8,1.7,2.9
           c2,5.6,6.9,10,12.5,11.3c9.2,2.2,18.7-3.8,20.9-13c0.1-0.6,0.3-1.1,0.5-1.5c0.1-0.6,0.2-1.2,0.2-1.8
           C132.2,393,132.1,392.1,132,391.2z"
              />
            </g>
          </svg>
        ) : (
          <svg
            id="svg3994"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 334.66 34.82"
          >
            <linearGradient
              id="my-cool-gradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop
                offset={`${(stage + idx) * 15}%`}
                stopColor="#64a7ed"
                stopOpacity="1"
              />
              <stop
                offset={`${stage * 25 + idx * 10}%`}
                stopColor="#e6e6e6"
                stopOpacity="1"
              />
            </linearGradient>
            <g id="g4088">
              <path
                fill="url(#my-cool-gradient)"
                id="path4102"
                d="M12.45,34.25A14.8,14.8,0,0,1,5.13,30C1.7,26.51.29,22.85.29,17.34S1.83,8,5.37,4.61a14.21,14.21,0,0,1,8-4.27C18-.48,23.25.08,26.23,1.71a18.43,18.43,0,0,1,8.16,9.62,9.35,9.35,0,0,0,1.72,3c.82.76,1.28.88,4.39,1.12,1.91.15,16,.27,31.21.27,27.59,0,27.74,0,28.64-.66s1.07-.95,3.16-5.39C107.59,1,118.34-2.53,127.3,1.86a17.41,17.41,0,0,1,7.56,7.83c1.89,4.18,2.19,4.69,3.13,5.39s1,.66,30.74.66c34.07,0,31,.29,32.4-3,2.28-5.3,4.19-7.82,7.58-10A15.49,15.49,0,0,1,218.05.08,15.66,15.66,0,0,1,230.3,5.2a17.28,17.28,0,0,1,4.7,8.14c.12.62.29,1.38.38,1.68.14.53,1.92.56,31.61.56,36.34,0,32.37.44,34.1-3.76A18.06,18.06,0,0,1,306,4.54,16.58,16.58,0,0,1,318.53,0a17.24,17.24,0,0,1,16.39,16.38,17.58,17.58,0,0,1-17.38,18.4,22.09,22.09,0,0,1-8.21-2.15,18.72,18.72,0,0,1-8.1-9.54c-.81-2.38-1.91-3.63-3.5-4s-61.28-.4-61.86,0a5.78,5.78,0,0,0-.88,2.28,17.54,17.54,0,0,1-20.86,13A18,18,0,0,1,201.61,23a9.6,9.6,0,0,0-1.68-2.92l-1-1H138.45l-1,1.07a9.2,9.2,0,0,0-1.59,2.8,17.92,17.92,0,0,1-12.4,11.32,17.71,17.71,0,0,1-21.07-11.75A7.61,7.61,0,0,0,101,20.22L99.89,19.1l-28.44,0c-15.64,0-29.86.12-31.59.24-3.07.2-3.19.23-4,1.24a10,10,0,0,0-1.4,2.75,18.86,18.86,0,0,1-8.34,9.72c-3.2,1.63-9.14,2.13-13.64,1.16Z"
                transform="translate(-0.29 0.04)"
              />
            </g>
          </svg>
        )}
      </div>
      <div className={isAffiliate ? "stages" : "stages-three"}>
        {tasks.map((el, idx) => (
          <div key={idx} className={idx + 1 <= stage ? "active" : ""}>
            {el}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Header;
