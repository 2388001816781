const initialState = {
  filled: [false, false, false, false, false, false, false],
  loading: false,
  data: {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    referral_code: "5468442142",
    password: "",
    register_type: 4,
    site_id: "affiliate",
    payment_method_nonce: "",
    countryCodeAlpha2: "US",
    streetAddress: "",
    city: "",
    state: "",
    app_id: "shuffle",
    "1qr_card_count": 10,
    upgrade100card: 0,
    buyMarketplace: 0,
    deviceProximity: 0,
    shippingCountry: "US",
    shippingAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingPostalCode: "",
    cardholder_name: "",
    shuffle_pro: "0",
    no_discount: 0,
    rcatoken: "",
    c_code: "d8vbp1egqq",
  },
  pack: [
    {
      name: "Shuffle Subscription",
      value: 29.99,
      is_monthly: true,
      text:
        'Shuffle: $19.99 per month 10 Business Cards, Landing Pages and "Self" Branding and Marketing materials & Unlimited Contacts.',
      img: (
        <img src="/shuffle-logo.svg" className="shuffle" alt="shuffle_logo" />
      ),
      id: 0,
    },
  ],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM": {
      state.filled[action.payload] = action.value;
      return state;
    }
    case "LOADER": {
      state.loading = action.isLoading;
      return state;
    }
    case "PACK": {
      state.pack = action.pack;
      return state;
    }
    case "DATA": {
      switch (action.data_type) {
        case "FIRST_NAME":
          state.data.first_name = action.data;
          break;
        case "LAST_NAME":
          state.data.last_name = action.data;
          break;
        case "PHONE":
          state.data.phone = action.data;
          break;
        case "EMAIL":
          state.data.email = action.data;
          break;
        case "PASSWORD":
          state.data.password = action.data;
          break;
        case "NONCE":
          state.data.payment_method_nonce = action.data;
          break;
        case "CARD_HOLDER":
          state.data.cardholder_name = action.data;
          break;
        case "COUNTRY_CODE":
          state.data.countryCodeAlpha2 = action.data;
          break;
        case "STREET_ADDR":
          state.data.streetAddress = action.data;
          break;
        case "CITY":
          state.data.city = action.data;
          break;
        case "STATE":
          state.data.state = action.data;
          break;
        case "CAPTCHA":
          state.data.rcatoken = action.data;
          break;
        default:
          return state;
      }
      return state;
    }
    default:
      return state;
  }
};

export default Reducer;
