import React, { useEffect, useState } from "react";
import "../styles/switch.scss";

const Switch = ({ val1, val2, getValue, defaultValue }) => {
  const [val, setVal] = useState(defaultValue);

  useEffect(() => {
    getValue && getValue(val);
  }, [val]);

  return (
    <div className={`${val ? "yes" : "no"} switch`}>
      <div
        onClick={() => {
          setVal(true);
        }}
        className={val === true && "white"}
      >
        {val1 ? val1 : "yes"}
      </div>
      <div
        onClick={() => {
          setVal(false);
        }}
        className={val === false && "white"}
      >
        {val2 ? val2 : "no"}
      </div>
    </div>
  );
};

export default Switch;
